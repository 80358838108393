import { useQuery } from '@tanstack/react-query';
import { SearchFilter } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getAllSearchFilters } from '../../server/api-proxy/search-filters';

const searchFiltersFetchFn = async () => {
    const response = await getAllSearchFilters();

    const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

    if (asyncError) {
        // we expect a 403 if the user does not have ownership of the address.
        // Just return false on error
        throw new Error(asyncError);
    }

    return response;
};

export const useSearchFiltersQuery = () =>
    useQuery<SearchFilter[]>({
        queryKey: uiCacheKeyBuilderMap.searchFilters(),
        queryFn: searchFiltersFetchFn,
        retry: false
    });
