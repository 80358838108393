import { useEffect, useState } from 'react';

// custom hook used to combat rehydration issues
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export const useHasMounted = (): boolean => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []); // this code will run when the value of 'value' changes

    return hasMounted;
};
