import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ListingGraph } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getBulkListingsById } from '../../server/api-proxy/listings';

const bulkListingsFetchFn =
    ({ ids }: { ids: string[] }) =>
    async (): Promise<ListingGraph[]> => {
        if (!ids || ids.length === 0) {
            throw new Error('id(s) required to fetch bulk listings');
        }

        const data = await getBulkListingsById({ listingIds: ids });
        const { error: { message: loadError = '' } = {} } = data;

        if (loadError) {
            throw new Error(loadError);
        }

        return data;
    };

export const useBulkListings = ({ ids }: { ids: string[] }) => {
    const queryClient = useQueryClient();

    return useQuery({
        enabled: Array.isArray(ids) && ids?.length > 0,
        queryKey: uiCacheKeyBuilderMap.bulkListings({ listingIds: ids }),
        initialData() {
            const listingGraphs = (ids ?? []).reduce<ListingGraph[]>((acc, id) => {
                const listingGraph = queryClient.getQueryData<ListingGraph>(['listing', id]);

                if (listingGraph) {
                    acc.push(listingGraph);
                }

                return acc;
            }, []);

            return listingGraphs.length === ids.length ? listingGraphs : undefined;
        },
        queryFn: bulkListingsFetchFn({ ids }),
        retry: false
    });
};
