// Next SSR server side cannot use browser-defined local storage
// This util provides SSR safe methods to attempt to access the values

// @ts-ignore - process.browser is defined by nextjs
const _isInBrowser = (): boolean => Boolean(process?.browser);

const _getFromLocalStorage = (key: string): string | null => {
    if (!_isInBrowser()) {
        return null;
    }

    return localStorage.getItem(key);
};

const _getBooleanFromLocalStorage = (key: string): boolean => _getFromLocalStorage(key) === 'true';

const _removeFromLocalStorage = (key: string): void => {
    if (!_isInBrowser()) {
        return;
    }

    return localStorage.removeItem(key);
};

const _setInLocalStorage = (key: string, value: string): void => {
    if (!_isInBrowser()) {
        return;
    }

    // @ts-ignore - process.browser is defined by nextjs
    return localStorage.setItem(key, value);
};

export const nextLocalStorage = {
    isInBrowser: _isInBrowser,
    get: _getFromLocalStorage,
    getAsBoolean: _getBooleanFromLocalStorage,
    remove: _removeFromLocalStorage,
    set: _setInLocalStorage
};
