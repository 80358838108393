import { PersistedUserPreferenceValues } from '@bladebinge/types';

export const DEFAULT_USER_PREFERENCES: PersistedUserPreferenceValues & {
    enableFinancingPaymentOption: boolean;
} = {
    browsingPreferences: {
        listingPagingLimit: 25,
        listingViewStylePreference: 'grid'
    },
    favorites: {
        showSoldOutItems: true
    },
    communication: {
        activeEmailSubscriptions: {
            ALL_REMINDERS_OPT_OUT: false,
            BUYER_REVIEW: true,
            INACTIVE_ACCOUNT: true,
            PAYOUT_ACCOUNT_CONNECTION: true,
            UNREAD_MESSAGE: true,
            SALE_AWAITING_SHIPMENT: true,
            SELLER_REVIEW: true
        }
    },
    locale: 'en',
    enableFinancingPaymentOption: true
};
