import { UiErrorObject } from '@bladebinge/types';
import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';
import { normalizedApiError } from '../utils/normalized-api-error';

export const logoutUserGetRequest = async (signal?: AbortSignal): Promise<void | UiErrorObject> => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.logout.build(),
            options: {
                method: 'GET',
                ...(signal ? { signal } : {})
            }
        });
        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};
