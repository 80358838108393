import { NextPageContext } from 'next';
import type { Listing, RegistrationFormState, SupportedLanguage } from '@bladebinge/types';
import { DEFAULT_LANGUAGE_CODE } from '@bladebinge/web-service-common/src/constants/supported-language-keys';
import { nextLocalStorage } from '../../utils/next-local-storage-helper';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const createPlaidLinkToken = async (userId: string, signal: AbortSignal) => {
    try {
        const tokenResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.createPlaidLinkToken.build({ userId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });
        return tokenResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const fetchLoggedInUser = async ({ userId }: { userId: string }) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchLoggedInUser.build({ userId })
        });

        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const fetchUserActiveProfile = async (userId: string, signal?: AbortSignal) => {
    try {
        const profileResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserActiveProfile.build({ userId }),
            options: {
                method: 'GET',
                ...(signal ? { signal } : {})
            }
        });

        // we replace listing here because when fetching account we know the listing owner already
        // this avoids circular dependency issues
        return profileResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const fetchUserAccount = async (userId: string, ctx?: NextPageContext) => {
    try {
        const accountResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserAccount.build({ userId }),
            ctx
        });

        // we replace listing here because when fetching account we know the listing owner already
        // this avoids circular dependency issues
        return {
            ...accountResponse,
            ...(accountResponse?.listings
                ? {
                      listings: accountResponse.listings.map(({ owner, ...listingProps }: Listing) => ({
                          ...listingProps
                      }))
                  }
                : {})
        };
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const registerAccount = async (registrationFormData: RegistrationFormState & { referredBy?: string }) => {
    try {
        const lng = nextLocalStorage.get('userLocale') || DEFAULT_LANGUAGE_CODE;

        const registerResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.registerAccount.build(),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    agreeToTerms: registrationFormData?.agreeToTerms,
                    confirmedPassword: registrationFormData?.confirmedPassword,
                    email: registrationFormData?.email,
                    firstName: registrationFormData?.firstName,
                    lastName: registrationFormData?.lastName,
                    lng: lng as SupportedLanguage,
                    password: registrationFormData?.password,
                    username: registrationFormData?.username,
                    ...(registrationFormData?.referredBy ? { referredBy: registrationFormData.referredBy } : {})
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return registerResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const updateActiveUserProfile = async ({
    activeProfileId = null,
    userId
}: {
    activeProfileId: string | null;
    userId: string;
}) => {
    try {
        const updateResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.updateActiveUserProfile.build({ userId }),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    activeProfileId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return updateResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const updateUserPersonalInfo = async ({
    userId,
    email,
    firstName,
    lastName
}: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
}) => {
    try {
        const updateResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.updateUserPersonalInfo.build({ userId }),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    firstName,
                    lastName
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return updateResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};
