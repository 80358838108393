// This coded is identical to code in common
// alias of code here is intentional
// importing all of common in next-ui causes node import errors in client code due to node/backend dependencies

// maybe can fix later by moving all node code into API but for now just leaving a duplicate set of url resolver methods
const getProxyApiBaseUrl = (): string => {
    // Currently these env vars should be set to fully-resolveable IP:port or hostname addresses
    const DIRECT_API_URL = process?.env?.ENV_API_SERVER_URL
        ? `${process.env.ENV_API_SERVER_URL}/`
        : 'http://localhost:1557/';
    const UI_PROXY_TO_API_URL = process?.env?.ENV_UI_SERVER_URL
        ? `${process.env.ENV_UI_SERVER_URL}/api/`
        : 'http://localhost:1558/api/';

    // @ts-ignore - process.browser is defined by nextjs
    const isClientSideCode = typeof window !== 'undefined';
    // console.log('REQUEST THROUGH', isClientSideCode ? 'CLIENT PROXY' : 'NODE SERVER FETCH');
    // In browser/client code fetch requests should hit the local UI server's proxy to the API
    // In server-side (SSR) code we can fetch directly from the API
    return isClientSideCode ? UI_PROXY_TO_API_URL : DIRECT_API_URL;
};

export const clientOrServerApiUrlResolver = (urlNoQueryString: string) => {
    let protocol: string = '';
    try {
        protocol = new URL(urlNoQueryString)?.protocol;
    } catch (e) {
        // ignore URL parse failure if url is relative
    }

    // return protocol ? urlNoQueryString : `${getProxyApiBaseUrl()}${urlNoQueryString}`;
    return (protocol ? urlNoQueryString : new URL(urlNoQueryString, getProxyApiBaseUrl()).toString()) as string;
};
