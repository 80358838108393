import { useQuery } from '@tanstack/react-query';
import { UserProfileDisplayGraph } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { PROXY_ROUTES } from '../../../server/api-proxy/ui-proxy-routes';
import { normalizedFetch } from '../../../server/api-proxy/utils/normalized-fetch';

const fetchUserActiveProfileFn =
    ({ userId, signal }: { userId?: string | null; signal?: AbortSignal }) =>
    async (): Promise<UserProfileDisplayGraph> => {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserActiveProfile.build({ userId }),
            options: {
                method: 'GET',
                ...(signal ? { signal } : {})
            }
        });

        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            throw new Error(asyncError);
        }

        return response;
    };

export const useLoggedInUserActiveProfile = ({ userId }: { userId?: string | null }, signal?: AbortSignal) =>
    useQuery({
        queryKey: uiCacheKeyBuilderMap.loggedInUserActiveProfile({ loggedInUserId: userId }),
        queryFn: fetchUserActiveProfileFn({
            userId,
            signal
        }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        enabled: Boolean(userId)
    });
