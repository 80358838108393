import type { PersistedUserPreferenceValues } from '@bladebinge/types';
import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getUserPreferences } from '../../../server/api-proxy/user-preferences';

const fetchUserPreferencesQueryFn =
    ({ userId }: { userId: string }) =>
    async (): Promise<PersistedUserPreferenceValues> => {
        const response = await getUserPreferences({
            userId
        });

        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            // we expect a 404 if the user does not have a referrer. Just return false on error
            throw new Error(asyncError);
        }

        return response;
    };

export const usePersistedUserPreferences = ({ userId }: { userId?: string | null }) =>
    useQuery({
        queryKey: uiCacheKeyBuilderMap.loggedInUserPersistentUserPreferences({ loggedInUserId: userId }),
        queryFn: fetchUserPreferencesQueryFn({
            userId: userId as string
        }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        enabled: Boolean(userId)
    });
