import React from 'react';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { jwtDecode } from 'jwt-decode';
import { NextPage, NextPageContext } from 'next';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CurrentUserPersonalData } from '@bladebinge/types';
import { MeContextProvider } from '../context/me/me-context';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { UserPreferencesConsumer, UserPreferencesProvider } from '../context/user-preferences/user-preferences-context';
import { getMuiTheme } from '../components/MuiTheme';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/global.css';
import { ViewPositionContextProvider } from '../context/view-position/view-position-context';
import { ReferralCodeContextProvider } from '../context/referral-code/referral-code-context';
import { authCookieFromContextOrBrowser } from '../utils/auth-cookie-from-context-or-browser';
import { ShoppingCartContextProvider } from '../context/shopping-cart/shopping-cart-context';
import { SearchFilterContextProvider } from '../context/search-filter/search-filter-context';
import { MessageImagesContextProvider } from '../context/image-uploads/message-images-context';
import { UserInterfaceNotificationsProvider } from '../context/user-interface-notifications/user-interface-notifications-context';
import { emptyInitialI18NextConfig } from '../i18n-initial-config';

const clientSideEmotionCache = createEmotionCache();
const tanstackQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            throwOnError: false,
            staleTime: 1000 * 60 * 3
        }
    }
});

interface AppPropsWithEmotionCache extends AppProps {
    readonly emotionCache?: EmotionCache;
}

const UiApp = ({
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
}: AppPropsWithEmotionCache): React.ReactElement => {
    const isDev = process.env.NODE_ENV !== 'production';
    return (
        <StyledEngineProvider injectFirst>
            <QueryClientProvider client={tanstackQueryClient}>
                {isDev && <ReactQueryDevtools initialIsOpen={false} />}
                <HydrationBoundary state={pageProps.dehydratedState}>
                    <SearchFilterContextProvider>
                        <ReferralCodeContextProvider>
                            <ShoppingCartContextProvider>
                                <MeContextProvider currentUser={pageProps.currentUser}>
                                    <UserInterfaceNotificationsProvider>
                                        <ViewPositionContextProvider>
                                            <MessageImagesContextProvider>
                                                <UserPreferencesProvider>
                                                    <UserPreferencesConsumer>
                                                        {({ themePreference }) => (
                                                            <CacheProvider value={emotionCache}>
                                                                <MuiThemeProvider theme={getMuiTheme(themePreference)}>
                                                                    <CssBaseline />
                                                                    <Component {...pageProps} />
                                                                </MuiThemeProvider>
                                                            </CacheProvider>
                                                        )}
                                                    </UserPreferencesConsumer>
                                                </UserPreferencesProvider>
                                            </MessageImagesContextProvider>
                                        </ViewPositionContextProvider>
                                    </UserInterfaceNotificationsProvider>
                                </MeContextProvider>
                            </ShoppingCartContextProvider>
                        </ReferralCodeContextProvider>
                    </SearchFilterContextProvider>
                </HydrationBoundary>
            </QueryClientProvider>
        </StyledEngineProvider>
    );
};

UiApp.getInitialProps = async ({ Component, ctx }: { Component: NextPage; ctx: NextPageContext }) => {
    const authCookie = authCookieFromContextOrBrowser(ctx);
    let currentUser: CurrentUserPersonalData | null = null;

    if (authCookie) {
        currentUser = jwtDecode(authCookie);
    }

    const pageProps = {
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
        currentUser
    };

    return { pageProps };
};

export default appWithTranslation(UiApp, emptyInitialI18NextConfig);
