import { useMemo } from 'react';
import querystring from 'query-string';
import { useRouter } from 'next/router';

/* eslint-disable react-hooks/exhaustive-deps */
export const useRedirectToLogin = () => {
    const router = useRouter();
    const { pathname, query } = router;

    const redirectToLogin = useMemo(
        () => (onlyForUserRoutes?: boolean) => {
            if (onlyForUserRoutes && !pathname.includes('/users/')) {
                return;
            }

            router.push(`/login?redirect=${encodeURIComponent(`${pathname}?${querystring.stringify(query)}`)}`);
        },
        [pathname, query]
    );

    return redirectToLogin;
};
/* eslint-enable react-hooks/exhaustive-deps */
