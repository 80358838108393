import type {
    ConfirmedOrder,
    CurrentUserPersonalData,
    ReportTimeRange,
    ReportUiTimeRange,
    ShoppingCartData,
    UserProfile
} from '@bladebinge/types';
import { isClientSide } from '../is-client-side';

const BINGE_CART_LOCAL_STORAGE_KEY = 'bingeCart';
const BINGE_ACTIVE_AVATAR_THUMBNAIL_LOCAL_STORAGE_KEY = 'bingeActiveAvatarThumbnail';
const BINGE_ACTIVE_PROFILE_ID_LOCAL_STORAGE_KEY = 'bingeActiveProfile';
const BINGE_ADMIN_ACTIVE_PROFILE_LOCAL_STORAGE_KEY = 'bingeAdminActiveProfile';
const BINGE_ME_LOCAL_STORAGE_KEY = 'bingeMe';
const BINGE_PENDING_ORDERS_LOCAL_STORAGE_KEY = 'bingePendingOrder';
const BINGE_ADMIN_CUSTOM_TIME_RANGE_KEY = 'bingeAdminReportCustomTimestamps';

// exported key names for consistenby using next local storage
export const BINGE_LISTING_PAGING_LIMIT = 'listingPagingLimit';
export const BINGE_LISTING_VIEW_STYLE_PREFERENCE = 'listingViewStyle';
export const BINGE_THEME_PREFERENCE = 'themePreference';

const EMPTY_CART = { items: {}, shipments: {} };

const jsonGetCurry =
    <T>(key: string, emptyValue: T) =>
    (): T => {
        if (!isClientSide()) {
            return emptyValue;
        }

        return localStorage.getItem(key)
            ? (JSON.parse(localStorage.getItem(key) as string) as unknown as T)
            : emptyValue;
    };

const stringGetCurry = (key: string) => (value?: string) => {
    // local storage only works once next is in browser
    if (!isClientSide()) {
        return null;
    }

    const storedIdAsString = typeof localStorage.getItem(key) === 'string' ? localStorage.getItem(key) : null;

    return storedIdAsString ? storedIdAsString : null;
};

const jsonSetCurry =
    <T>(key: string, emptyValue: T, removalCheck: (value: T) => boolean) =>
    (value: T = emptyValue) => {
        if (!isClientSide()) {
            return;
        }

        if (removalCheck(value)) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    };

const stringSetCurry = (key: string) => (value?: string) => {
    if (!isClientSide()) {
        return;
    }

    if (!value) {
        localStorage.removeItem(key);
        return;
    }

    localStorage.setItem(key, value);
};

// Curried Getters
export const getActiveProfileIdFromLocalStorage = stringGetCurry(BINGE_ACTIVE_PROFILE_ID_LOCAL_STORAGE_KEY);
export const getActiveAvatarThumbnailFromLocalStorage = stringGetCurry(BINGE_ACTIVE_AVATAR_THUMBNAIL_LOCAL_STORAGE_KEY);
export const getAdminActiveProfileFromLocalStorage = jsonGetCurry<UserProfile | null>(
    BINGE_ADMIN_ACTIVE_PROFILE_LOCAL_STORAGE_KEY,
    null
);
export const getAdminCustomReportTimestampsFromLocalStorage = jsonGetCurry<ReportTimeRange | null>(
    BINGE_ADMIN_CUSTOM_TIME_RANGE_KEY,
    null
);
export const getCartDataFromLocalStorage = jsonGetCurry<ShoppingCartData>(BINGE_CART_LOCAL_STORAGE_KEY, EMPTY_CART);
export const getMeFromLocalStorage = jsonGetCurry<CurrentUserPersonalData | null>(BINGE_ME_LOCAL_STORAGE_KEY, null);
export const getPendingOrderFromLocalStorage = jsonGetCurry<ConfirmedOrder | null>(
    BINGE_PENDING_ORDERS_LOCAL_STORAGE_KEY,
    null
);

// Curried Setters
export const setActiveProfileIdInLocalStorage = stringSetCurry(BINGE_ACTIVE_PROFILE_ID_LOCAL_STORAGE_KEY);
export const setActiveAvatarThumbnailInLocalStorage = stringSetCurry(BINGE_ACTIVE_AVATAR_THUMBNAIL_LOCAL_STORAGE_KEY);
export const setCartDataInLocalStorage = jsonSetCurry<ShoppingCartData>(
    BINGE_CART_LOCAL_STORAGE_KEY,
    EMPTY_CART,
    (cart: ShoppingCartData) => Object.keys(cart?.items ?? []).length === 0
);

export const setAdminActiveProfileInLocalStorage = jsonSetCurry<UserProfile | null>(
    BINGE_ADMIN_ACTIVE_PROFILE_LOCAL_STORAGE_KEY,
    null,
    (activeProfile: UserProfile | null) => activeProfile === null
);

export const setAdminCustomReportTimestampsInLocalStorage = jsonSetCurry<ReportUiTimeRange | null>(
    BINGE_ADMIN_CUSTOM_TIME_RANGE_KEY,
    null,
    (customTimeRange: ReportUiTimeRange | null) => customTimeRange === null
);

export const setMeInLocalStorage = jsonSetCurry<CurrentUserPersonalData | null>(
    BINGE_ME_LOCAL_STORAGE_KEY,
    null,
    (me: CurrentUserPersonalData | null) => me === null
);

export const setPendingOrderInLocalStorage = jsonSetCurry<ConfirmedOrder | null>(
    BINGE_PENDING_ORDERS_LOCAL_STORAGE_KEY,
    null,
    (order: ConfirmedOrder | null) => order === null
);
