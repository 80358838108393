import type { SearchFilter } from '@bladebinge/types';

export const groupFiltersByFilterProperty = <T>({
    filters = [],
    groupOnProperty
}: {
    filters: SearchFilter[];
    groupOnProperty: keyof SearchFilter;
}): {
    [key: string]: SearchFilter[];
} =>
    filters.reduce((acc: { [filterTypeSlug: string]: SearchFilter[] }, filter: SearchFilter) => {
        const groupProp = filter[groupOnProperty] as string;
        acc[groupProp] = acc[groupProp] || [];
        acc[groupProp].push(filter);
        return acc;
    }, {});
