/// <reference lib="dom" />
import { default as jsCookies } from 'js-cookie';
import { NextPageContext } from 'next';
import fetch from 'cross-fetch';
import { getPubSub } from '@bladebinge/web-service-common/src/pubsub/get-pub-sub';
import { UNMOUNT_ABORT } from '../../../utils/constants';
import { clientOrServerApiUrlResolver } from './client-or-server-api-url-resolver';
import { normalizedRequestHeaders } from './normalized-request-headers';

const processAuthenticationCookieFallbackSupport = (response: Response) => {
    // eslint-disable-next-line
    const ENV_BINGE_AUTH_COOKIE_NAME = process.env.ENV_BINGE_AUTH_COOKIE_NAME;
    // eslint-disable-next-line
    const ENV_BINGE_AUTH_HEADER = process.env.ENV_BINGE_AUTH_HEADER;

    if (ENV_BINGE_AUTH_COOKIE_NAME) {
        const browserAuthCookie = jsCookies.get(ENV_BINGE_AUTH_COOKIE_NAME);
        if (!browserAuthCookie && ENV_BINGE_AUTH_HEADER) {
            const backupAuthHeaderTokenValue = response?.headers?.get(ENV_BINGE_AUTH_HEADER);
            if (backupAuthHeaderTokenValue) {
                jsCookies.set(ENV_BINGE_AUTH_COOKIE_NAME, backupAuthHeaderTokenValue);
            }
        }
    }
};

export const normalizedFetch = async ({
    endpoint,
    ctx,
    options = {}
}: {
    endpoint: string;
    ctx?: NextPageContext;
    options?: RequestInit;
}) => {
    try {
        const absoluteUrl = clientOrServerApiUrlResolver(endpoint);
        const { headers, ...restOfOptions } = options;

        const fetchOptions = {
            credentials: 'include',
            ...restOfOptions,
            headers: normalizedRequestHeaders(ctx as NextPageContext, headers)
        };

        // @ts-expect-error - not sure why credentials include doesn't match type here
        const response = await fetch(absoluteUrl, fetchOptions);

        processAuthenticationCookieFallbackSupport(response);

        // if we get a 401 the user session has been lost or the jwt refresh token TTL has expired, redirect to login with redirect back to current page
        if (
            typeof window !== 'undefined' &&
            response.status === 401 // if we get a 401 the user session has been lost or the jwt refresh token TTL has expired, redirect to login with redirect back to current page
        ) {
            getPubSub().emit('uiLogout');
            return {};
        }

        const jsonResponse = await response.json();

        return jsonResponse;
    } catch (err) {
        if (options?.signal?.reason === UNMOUNT_ABORT) {
            return {};
        }

        const errorMessage = `${((err as Error) ?? {})?.message}`;

        return {
            error: {
                message: `${errorMessage}: ${((err as Error) ?? {})?.stack}`
            }
        };
    }
};
