import type {
    CartSalesTaxData,
    ShoppingCartData,
    ShoppingCartItem,
    ShoppingCartItemsByListingId,
    ShoppingCartShipment,
    ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId
} from '@bladebinge/types';
import { EMPTY_CART } from './constants';

export const getCartWithAddedShipments = (
    currentCart: ShoppingCartData,
    addedShipments: ShoppingCartShipment[]
): ShoppingCartData => {
    const { items, shipments: currentCartShipments } = currentCart;

    return {
        items,
        shipments: addedShipments.reduce<ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId>(
            (acc: ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId, shipmentData: ShoppingCartShipment) => {
                const { sellerIdUnderscoreFromAddressId } = shipmentData;
                acc[sellerIdUnderscoreFromAddressId] = shipmentData;
                return acc;
            },
            { ...currentCartShipments }
        )
    };
};

export const getCartWithRemovedShipments = (
    currentCart: ShoppingCartData,
    removedShipments: ShoppingCartShipment[]
): ShoppingCartData => {
    const { items, shipments: currentCartShipments } = currentCart;

    return {
        items,
        shipments: Object.entries(currentCartShipments).reduce<ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId>(
            (acc, [sellerIdUnderscoreFromAddressId, shipmentData]) => {
                if (
                    removedShipments.some(
                        ({ sellerIdUnderscoreFromAddressId: idBeingRemoved }) =>
                            sellerIdUnderscoreFromAddressId === idBeingRemoved
                    )
                ) {
                    return acc;
                }

                acc[sellerIdUnderscoreFromAddressId] = shipmentData;

                return acc;
            },
            {}
        )
    };
};

export const getCartWithAddedItems = (
    currentCart: ShoppingCartData,
    addedItems: ShoppingCartItem[]
): ShoppingCartData => {
    const { items: currentCartItems, shipments } = currentCart;

    return {
        items: addedItems.reduce<ShoppingCartItemsByListingId>(
            (acc: ShoppingCartItemsByListingId, itemData: ShoppingCartItem) => {
                const { id: itemId } = itemData;

                if (currentCartItems[itemId]) {
                    const { quantity: previousQty } = currentCartItems[itemId];
                    const { quantity: addedQty } = itemData;

                    currentCartItems[itemId].quantity = previousQty + addedQty;
                    acc[itemId] = currentCartItems[itemId];

                    return acc;
                }

                acc[itemId] = itemData;

                return acc;
            },
            { ...currentCartItems }
        ),
        shipments
    };
};

export const getCartWithRemovedItems = (
    currentCart: ShoppingCartData,
    removedItems: ShoppingCartItem[]
): ShoppingCartData => {
    const { items: currentCartItems, shipments } = currentCart;
    const removedItemIds = removedItems.map(({ id }) => id);

    const updatedCart = {
        items: Object.entries(currentCartItems).reduce<ShoppingCartItemsByListingId>((acc, [itemId, cartItemData]) => {
            const itemBeingRemoved = removedItems.find(({ id }) => itemId === id);

            if (itemBeingRemoved) {
                const { quantity: removingQty } = itemBeingRemoved;
                const { quantity: cartQty } = cartItemData;
                const qtyRemaining = cartQty - removingQty;

                if (qtyRemaining > 0) {
                    cartItemData.quantity = qtyRemaining;
                    acc[itemId] = cartItemData;
                }

                return acc;
            }

            acc[itemId] = cartItemData;

            return acc;
        }, {}),
        shipments: Object.entries(shipments).reduce<ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId>(
            (acc, [sellerIdUndercsoreFromAddressId, shipment]) => {
                const { listingIds = [] } = shipment;

                // if any items in a shipment were removed the shipping data is no longer valid due to possible contents/weight/dimension changes
                const shipmentContainedRemovedItems = listingIds.find((listingId) =>
                    removedItemIds.includes(listingId)
                );

                if (!shipmentContainedRemovedItems) {
                    acc[sellerIdUndercsoreFromAddressId] = shipment;
                }

                return acc;
            },
            {}
        )
    };

    return Object.keys(updatedCart.items).length === 0 ? EMPTY_CART : updatedCart;
};

export const updateShipmentTaxData = (
    currentCart: ShoppingCartData,
    taxMapBySellerIdUnderscoreFromAddressId: CartSalesTaxData
) => {
    const { items, shipments: currentCartShipments } = currentCart;

    return {
        items,
        shipments: Object.entries(currentCart.shipments).reduce<ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId>(
            (
                acc: ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId,
                [sellerIdUnderscoreFromAddressId, shipmentData]
            ) => {
                const correspondingTaxData = taxMapBySellerIdUnderscoreFromAddressId?.[sellerIdUnderscoreFromAddressId];

                acc[sellerIdUnderscoreFromAddressId] = {
                    ...shipmentData,
                    ...correspondingTaxData
                };

                return acc;
            },
            { ...currentCartShipments }
        )
    };
};
