import type { ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId } from '@bladebinge/types';

export const calculateShippingTotal = (
    shipmentsInCart: ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId
): number =>
    Object.values(shipmentsInCart ?? {}).reduce<number>((acc, { amount, listingIds, shipmentItemQuantities }) => {
        // amount is not defined on 'freeShipping' items
        if (amount) {
            const numericShippingCost = parseFloat(`${amount}`);
            acc += numericShippingCost;
        }

        return acc;
    }, 0);
