import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getAllSearchFilters = async (signal?: AbortSignal) => {
    try {
        // list endpoint with no limit just returns an array of objects
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchAllSearchFilters.build(),
            ...(signal ? { options: { signal } } : {})
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
