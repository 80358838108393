export const DEFAULT_COMMISSION_RATES = {
    processing: 3.5 / 100,
    sales: 4 / 100
};

export const REFERRAL_COMMISION_RATES = {
    BLADE_BINGE_OFFICIAL_RETAIL_MERCHANDISE_SALE_PERCENTAGE: 5 / 100, // 5%
    RESALE_SHIPPING_CHARGE_COMMISSION_PERCENTAGE: 5 / 100, // 5% of shipping charge amount
    REGISTRATION_REFERRAL_FLAT_RATE_COMMISSION_DOLLARS: 0.5 // 50 cents per user
};

export const FINANCING_PROCESSING_FEE = 3 / 100;
