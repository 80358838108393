import { Image, ImageType } from '@bladebinge/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getUserImageUploads } from '../../../server/api-proxy/user-image-upload';

const userImageUploadsFetchFn =
    ({ limit, imageType, userId }: { limit: number; imageType: ImageType; userId: string }) =>
    async ({
        pageParam
    }: {
        pageParam?: { _offset?: string };
    }): Promise<{
        result: Image[];
        _total: number;
        _limit: number;
        _offset: number;
    }> => {
        if (!userId || !imageType || !pageParam || pageParam?._offset === undefined) {
            throw new Error('User ID and imageType are required to fetch user image uploads');
        }

        const response = await getUserImageUploads({
            queryParams: {
                limit: `${limit}`,
                imageType,
                offset: `${pageParam._offset}`
            },
            userId
        });
        const { error: { message: loadError = '' } = {} } = response;

        if (loadError) {
            throw new Error(loadError);
        }

        return response;
    };

export const useUserImageUploads = ({
    hasMounted = false,
    imageType = 'messageImages' as ImageType,
    limit,
    offset = 0,
    signal,
    userId
}: {
    hasMounted: boolean;
    imageType: ImageType;
    limit: number;
    offset?: number;
    signal?: AbortSignal;
    userId: string;
}) =>
    useInfiniteQuery({
        enabled: hasMounted && Boolean(userId),
        initialPageParam: offset === undefined ? { _offset: '0' } : { _offset: `${offset}` },
        queryKey: uiCacheKeyBuilderMap.userImageUploads({
            loggedInUserId: userId,
            imageType,
            uploadLimit: limit
        }),
        queryFn: userImageUploadsFetchFn({ limit, imageType, userId }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        getNextPageParam(lastPage) {
            const { _total, _limit, _offset } = lastPage;
            const nextPageOffset = _offset + _limit;
            const hasMore = nextPageOffset < _total;

            return hasMore ? { _offset: `${nextPageOffset}` } : undefined;
        },
        getPreviousPageParam(firstPage) {
            const { _limit, _offset } = firstPage;
            const previousPageOffset = _offset - _limit > 0 ? _offset - _limit : undefined;

            return previousPageOffset ? { _offset: `${previousPageOffset}` } : undefined;
        }
    });
