// TODO - determine standardized front-end error handling
const UNKNOWN_ERROR_MESSAGE = 'unknown error';

interface GenericSerializedError {
    [key: string]: unknown;
}

interface UiError extends GenericSerializedError {
    message?: string;
    stack?: string;
}

export interface UiErrorObject {
    error: UiError;
}

export const normalizedApiError = (err: unknown): UiErrorObject => {
    const errorJSON = err
        ? {
              name: (err as Error)?.name,
              message: (err as Error)?.message,
              stack: (err as Error)?.stack
          }
        : {
              message: UNKNOWN_ERROR_MESSAGE
          };

    return {
        error: errorJSON
    };
};
