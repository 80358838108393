import { default as nextI18NextConfig } from './next-i18next.config.js';

export const emptyInitialI18NextConfig = {
    i18n: {
        defaultLocale: nextI18NextConfig.i18n.defaultLocale,
        locales: nextI18NextConfig.i18n.locales
    },
    fallbackLng: {
        default: ['en'],
        'de-at': ['de'],
        'de-ch': ['de'],
        'de-li': ['de']
    }
};
