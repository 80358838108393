import { blue, blueGrey, green, grey, orange } from '@mui/material/colors';
import { createTheme, Theme as MaterialUITheme, ThemeOptions } from '@mui/material/styles';
import { bodyFont, headingFont } from '@bladebinge/web-service-common/src/constants/fonts';

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends MaterialUITheme {}
}

const DEFAULT_FONT_FAMILY = bodyFont.style.fontFamily;
const HEADING_FONT = {
    fontFamily: headingFont.style.fontFamily,
    fontWeight: 700
};

const COMMON_THEME: Partial<ThemeOptions> = {
    typography: {
        fontFamily: DEFAULT_FONT_FAMILY,
        h1: HEADING_FONT,
        h2: HEADING_FONT,
        h3: HEADING_FONT,
        h4: HEADING_FONT,
        h5: HEADING_FONT,
        h6: HEADING_FONT
    }
};

const DARK_THEME_BACKGROUND_COLOR = '#000000';
// '#1f2833'

/**
 * material-ui theme color pallete
 * @see https://material-ui.com/style/color/
 */
const MuiDarkTheme = createTheme({
    ...COMMON_THEME,
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: grey[300],
                    backgroundColor: DARK_THEME_BACKGROUND_COLOR
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                containedPrimary: {
                    fontWeight: 'bold'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '> svg.MuiSvgIcon-root': {
                        fill: '#ffffff'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: grey[300],
                    backgroundColor: DARK_THEME_BACKGROUND_COLOR,
                    padding: '0 5px',
                    '&.Mui-focused': {
                        color: '#AFF0EC',
                        fontWeight: 'bold'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& $notchedOutline': {
                        borderColor: 'cyan',
                        borderWidth: '5px'
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#AFF0EC !important',
                        borderWidth: '20px'
                    }
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px #78909c inset',
                        WebkitTextFillColor: '#ffffff'
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#AFF0EC'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: blueGrey[800],
                        color: '#ffffff'
                    },
                    '&.Mui-selected': {
                        backgroundColor: blueGrey.A700,
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        '&:hover': {
                            backgroundColor: blueGrey[900],
                            color: '#AFF0EC'
                        }
                    }
                }
            }
        }
    },
    palette: {
        background: {
            default: '#000000',
            paper: DARK_THEME_BACKGROUND_COLOR
        },
        divider: grey[300],
        error: {
            light: '#E83FB8',
            main: '#F24167',
            dark: '#CC0000'
        },
        info: {
            light: green[300],
            main: green[500],
            dark: green[800]
        },
        primary: {
            light: '#AFF0EC',
            main: '#45a29e',
            dark: '#30706D'
        },
        secondary: {
            light: '#cecece',
            main: '#f8f8f8',
            dark: '#fefefe'
        },
        warning: {
            light: orange.A100,
            main: orange.A400,
            dark: orange.A700
        },
        mode: 'dark'
    }
});

const MuiLightTheme = createTheme({
    ...COMMON_THEME,
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: grey[300],
                    backgroundColor: blueGrey[900]
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '> svg.MuiSvgIcon-root': {
                        fill: '#000000'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: grey[700],
                    backgroundColor: '#f8f8f8',
                    padding: '0 5px',
                    '&.Mui-focused': {
                        color: grey[700]
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        webkitBoxShaodow: `0 0 0 100px #fff9c4 inset`,
                        webkitTextFillColor: '#000000'
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#000000'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#efefef',
                        color: '#333333'
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#eeeeee',
                        color: 'black',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        '&:hover': {
                            backgroundColor: '#eeeeee',
                            color: 'black'
                        }
                    }
                }
            }
        }
    },
    palette: {
        background: {
            default: '#000000',
            paper: '#ffffff'
        },
        divider: grey[300],
        error: {
            light: '#E83FB8',
            main: '#F24167',
            dark: '#CC0000'
        },
        info: {
            light: green[300],
            main: green[500],
            dark: green[800]
        },
        primary: {
            light: blue[300],
            main: blue[800],
            dark: blue[900]
        },
        secondary: {
            light: grey[50],
            main: grey[300],
            dark: grey[500]
        },
        warning: {
            light: orange[300],
            main: orange.A700,
            dark: orange[900]
        },
        mode: 'light'
    }
});

export type AppThemePreference = '' | 'dark' | 'light';

export const DARK_THEMES = ['dark'];
export const LIGHT_THEMES = ['light'];

export const getMuiTheme = (themePreference: AppThemePreference = 'dark') => {
    switch (themePreference) {
        case 'dark': {
            return MuiDarkTheme;
        }

        case 'light': {
            return MuiLightTheme;
        }

        default: {
            return MuiDarkTheme;
        }
    }
};
