import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface ReferralCodeContext {
    referredBy: string;
    clearReferredBy: () => void;
}

const referralCodeContext = createContext<ReferralCodeContext>({
    referredBy: '',
    clearReferredBy() {}
});

const { Provider } = referralCodeContext;

export const ReferralCodeContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const [referredBy, setReferredBy] = useState<string>('');
    const router = useRouter();

    useEffect(() => {
        const {
            pathname,
            query: { referrer = '', ...restOfQuery }
        } = router;

        if (referrer && referrer !== referredBy) {
            setReferredBy(Array.isArray(referrer) ? referrer[0] : referrer);
            router.push(
                {
                    pathname,
                    query: restOfQuery
                },
                undefined,
                { shallow: true }
            );
        }
    }, [referredBy, router]);

    const clearReferredBy = useCallback(() => setReferredBy(''), []);

    return (
        <Provider
            value={{
                referredBy,
                clearReferredBy
            }}
        >
            {children}
        </Provider>
    );
};

export const useReferralCodeContext = () => useContext(referralCodeContext);
