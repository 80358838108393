import { NextPageContext } from 'next';
import { authCookieFromContextOrBrowser } from '../../../utils/auth-cookie-from-context-or-browser';

// for now we are just forcing cookies through but we can expand as more headers are needed
export const normalizedRequestHeaders = (ctx?: NextPageContext, headers?: HeadersInit) => {
    const authCookie = authCookieFromContextOrBrowser(ctx);

    return authCookie
        ? {
              ...headers,
              Authorization: `bearer ${authCookie}`
          }
        : headers;
};
