import React, { createContext, useCallback, useContext, useState } from 'react';

interface ViewPositionContext {
    listingsViewPositionY: number;
    setListingsViewPositionY: (y: number) => void;
    setWindowScrollYPosition: (y: number) => void;
}

const browseContext = createContext<ViewPositionContext>({
    listingsViewPositionY: 0,
    setListingsViewPositionY(y: number) {},
    setWindowScrollYPosition(y: number) {}
});

const { Provider } = browseContext;

export const ViewPositionContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const [listingsViewPositionY, setListingsViewPositionY] = useState<number>(0);

    const setWindowScrollYPosition = useCallback((y: number) => {
        if (typeof window === 'undefined') {
            return;
        }

        window.scrollTo(0, y);
    }, []);

    return (
        <Provider
            value={{
                listingsViewPositionY,
                setListingsViewPositionY,
                setWindowScrollYPosition
            }}
        >
            {children}
        </Provider>
    );
};

export const useViewPositionContext = () => useContext(browseContext);
