import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { UserInterfaceNotificationCountsData, UserInterfaceNotificationMenuItemTypes } from '@bladebinge/types';
import { useQueryClient } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useMe } from '../me/me-context';
import { useHasMounted } from '../../hooks/use-has-mounted';
import { useLoggedInUserUserInterfaceNotificationCounts } from '../../hooks/react-query/logged-in-user-hooks/user-logged-in-user-user-interface-notification-counts';

const EMPTY_COUNTS_DATA = {
    NEW_MESSAGE: 0,
    SHIPMENT_AWAITING_SHIPMENT: 0,
    SHIPMENT_SHIPPING_LABEL_REQUIRED: 0,
    RATING_RECEIVED: 0,
    BUYER_RATING_NEEDED: 0,
    SELLER_RATING_NEEDED: 0,
    PRICE_DROP: 0,
    OUTGOING_PAYMENT_ERROR: 0,
    TOTAL: 0
};

const EMPTY_SORTED_COUNTS_DATA: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][] = [
    ['SHIPMENT_AWAITING_SHIPMENT', 0],
    ['SHIPMENT_SHIPPING_LABEL_REQUIRED', 0],
    ['BUYER_FEEDBACK_REQUESTED', 0],
    ['SELLER_FEEDBACK_REQUESTED', 0],
    ['NEW_MESSAGE', 0],
    ['PRICE_DROP', 0],
    ['FEEDBACK', 0],
    ['OUTGOING_PAYMENT_ERROR', 0]
];

interface UserInterfaceNotificationsContext {
    countsData: UserInterfaceNotificationCountsData;
    feedbackTotalCount: number;
    sortedCountsData: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][];
    invalidateNotificationsCounts(): void;
    isLoading: boolean;
    isPending: boolean;
    toggleMenu: () => void;
    isMenuOpen: boolean;
}

const userInterfaceNotificationsContext = createContext<UserInterfaceNotificationsContext>({
    countsData: EMPTY_COUNTS_DATA,
    feedbackTotalCount: 0,
    sortedCountsData: EMPTY_SORTED_COUNTS_DATA,
    invalidateNotificationsCounts() {},
    isLoading: false,
    isPending: false,
    toggleMenu() {},
    isMenuOpen: false
});

export const { Consumer: UserInterfaceNotificationsConsumer } = userInterfaceNotificationsContext;
const { Provider } = userInterfaceNotificationsContext;

export const UserInterfaceNotificationsProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { id: loggedInUserId } = useMe();
    const queryClient = useQueryClient();
    const hasMounted = useHasMounted();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const {
        data: countsData = EMPTY_COUNTS_DATA,
        isLoading: countsLoading,
        isPending: countsPending
    } = useLoggedInUserUserInterfaceNotificationCounts({
        hasMounted,
        userId: loggedInUserId
    });

    const sortedCountsData: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][] = useMemo(
        () => [
            ['SHIPMENT_AWAITING_SHIPMENT', countsData.SHIPMENT_AWAITING_SHIPMENT],
            ['SHIPMENT_SHIPPING_LABEL_REQUIRED', countsData.SHIPMENT_SHIPPING_LABEL_REQUIRED],
            ['BUYER_FEEDBACK_REQUESTED', countsData.BUYER_RATING_NEEDED],
            ['SELLER_FEEDBACK_REQUESTED', countsData.SELLER_RATING_NEEDED],
            ['NEW_MESSAGE', countsData.NEW_MESSAGE],
            ['PRICE_DROP', countsData.PRICE_DROP],
            ['FEEDBACK', countsData.RATING_RECEIVED],
            ['OUTGOING_PAYMENT_ERROR', countsData.OUTGOING_PAYMENT_ERROR]
        ],
        [countsData]
    );

    // memoize total feedback count for UserMenu Feedback tab
    const feedbackTotalCount = useMemo(
        () => countsData.BUYER_RATING_NEEDED + countsData.SELLER_RATING_NEEDED + countsData.RATING_RECEIVED,
        [countsData]
    );

    const invalidateNotificationsCounts = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: uiCacheKeyBuilderMap.loggedInUserUserInterfaceNotificationCounts({
                loggedInUserId
            })
        });
    }, [loggedInUserId, queryClient]);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <Provider
            value={{
                countsData,
                feedbackTotalCount,
                sortedCountsData,
                invalidateNotificationsCounts,
                isLoading: countsLoading,
                isPending: countsPending,
                toggleMenu,
                isMenuOpen
            }}
        >
            {children}
        </Provider>
    );
};

export const useUserInterfaceNotificationsContext = () => useContext(userInterfaceNotificationsContext);
