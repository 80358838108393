import { useQuery } from '@tanstack/react-query';
import { UserGraph } from '@bladebinge/types/src/api/models/user';
import { UiErrorObject } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { fetchLoggedInUser } from '../../../server/api-proxy/account';

const fetchLoggedInUserFn =
    ({ id }: { id?: string | null }) =>
    async () => {
        if (!id) {
            return;
        }

        const response = await fetchLoggedInUser({ userId: id });
        const { error: { message: asyncError = '' } = {} } = response as UiErrorObject;

        if (asyncError) {
            throw new Error(asyncError);
        }

        return response;
    };

export const useLoggedInUser = ({ id, initialData }: { id?: string | null; initialData?: UserGraph }) =>
    useQuery({
        enabled: Boolean(id),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        queryKey: uiCacheKeyBuilderMap.loggedInUser({ loggedInUserId: id }),
        queryFn: fetchLoggedInUserFn({ id }),
        ...(initialData ? { initialData } : {})
    });
