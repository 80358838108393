import type { ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId } from '@bladebinge/types';

export const calculateSalesTaxTotal = (
    shipmentsInCart: ShoppingCartShipmentsBySellerIdUnderscoreFromAddressId
): number => {
    const currentShipments = Object.values(shipmentsInCart ?? {});

    if (currentShipments.length === 0) {
        return 0;
    }

    // tax rollup total
    return Object.values(shipmentsInCart ?? {}).reduce<number>((acc, { buyerTaxTotal = 0 }) => {
        // amount is not defined on 'freeShipping' items
        if (buyerTaxTotal) {
            const numericTaxAmount = parseFloat(`${buyerTaxTotal}`);
            acc += numericTaxAmount;
        }

        return acc;
    }, 0);
};
