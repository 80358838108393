import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getUserPreferences = async ({ userId, signal }: { userId: string; signal?: AbortSignal }) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserPreferences.build({
                userId
            }),
            ...(signal ? { options: { signal } } : {})
        });

        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};
