import { default as jsCookies } from 'js-cookie';
import { default as nextCookies } from 'next-cookies';
import { NextPageContext } from 'next';

export const authCookieFromContextOrBrowser = (ctx?: NextPageContext): string | undefined => {
    // eslint-disable-next-line
    const ENV_BINGE_AUTH_COOKIE_NAME = process.env.ENV_BINGE_AUTH_COOKIE_NAME;

    if (!ENV_BINGE_AUTH_COOKIE_NAME) {
        throw new Error('no ENV_BINGE_AUTH_COOKIE_NAME in defined for the environment');
    }

    const ctxAuthCookie = ctx ? nextCookies(ctx)?.[ENV_BINGE_AUTH_COOKIE_NAME] : undefined;
    const browserAuthCookie = jsCookies.get(ENV_BINGE_AUTH_COOKIE_NAME);

    return ctxAuthCookie || browserAuthCookie;
};
