import { NextRouter } from 'next/router';
import { produce } from 'immer';

export const updateNextRouterQuery = ({
    pathname,
    router,
    shallow,
    updatedQuery,
    removeKeys = []
}: {
    pathname?: string;
    router: NextRouter;
    shallow: boolean;
    updatedQuery: { [key: string]: string | string[] | undefined };
    removeKeys?: string[];
}) => {
    const { query: currentQuery } = router;

    const immutableQuery = produce(currentQuery, (draftQuery) => {
        Object.entries(updatedQuery).forEach(([key, val]) => {
            if (val !== undefined) {
                draftQuery[`${key}`] = val;
            }
        });
        removeKeys.forEach((delKey) => {
            delete draftQuery[delKey];
        });
    });

    router.push(
        {
            query: immutableQuery,
            ...(pathname ? { pathname } : {})
        },
        undefined,
        {
            shallow
        }
    );
};
