import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { calculateBuyerFinancingCharge } from '@bladebinge/web-service-common/src/utils/calculate-buyer-financing-charge';
import type {
    CartSalesTaxData,
    ListingGraph,
    ShoppingCartData,
    ShoppingCartItem,
    ShoppingCartShipment
} from '@bladebinge/types';
import {
    getCartDataFromLocalStorage,
    setCartDataInLocalStorage
} from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { useBulkListings } from '../../hooks/react-query/use-bulk-listings';
import { getCalculatedCartSalesTax } from '../../server/api-proxy/ui-mutation-fns/get-calculated-cart-sales-tax';
import { useHasMounted } from '../../hooks/use-has-mounted';
import { getUniqueRandomId } from '../../utils/get-unique-random-id';
import { calculateSubtotal } from './utils/calculate-subtotal';
import { calculateSalesTaxTotal } from './utils/calculate-sales-tax-total';
import { calculateShippingTotal } from './utils/calculate-shipping-total';
import {
    getCartWithAddedItems,
    getCartWithAddedShipments,
    getCartWithRemovedItems,
    getCartWithRemovedShipments,
    updateShipmentTaxData
} from './utils/cart-contents-utils';
import { EMPTY_CART } from './utils/constants';

interface CartContext {
    addItemsToCart: (items: ShoppingCartItem | ShoppingCartItem[]) => void;
    addShipmentsToCart: (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => void;
    allItemsHaveFreeShipping?: boolean;
    buyerFinancingCharge: number;
    calculateSalesTaxError?: string;
    cartData: ShoppingCartData;
    cartId: string;
    cartItemsCount: number;
    cartItemsListingGraphs: ListingGraph[];
    clearCart: () => void;
    clearCartShipments: () => void;
    currency: string;
    hasCurrentTaxData?: boolean;
    includeFinancingProcessingFee?: boolean;
    isCalculatingSalesTax?: boolean;
    refetchTaxData: () => void;
    setShipToAddressId: (id: string) => void;
    subtotal: number;
    shipToAddressId?: string;
    shippingTotal: number;
    showCartDrawer: boolean;
    taxTotal: number;
    total: number;

    removeItemsFromCart: (items: ShoppingCartItem | ShoppingCartItem[]) => void;
    removeShipmentsFromCart: (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => void;
    setShowCartDrawer: (open: boolean) => void;
    setIncludeFinancingProcessingFee: (v: boolean) => void;
}

const shoppingCartContext = createContext<CartContext>({
    addItemsToCart(items: ShoppingCartItem | ShoppingCartItem[]) {},
    addShipmentsToCart(shipments: ShoppingCartShipment | ShoppingCartShipment[]) {},
    buyerFinancingCharge: 0,
    calculateSalesTaxError: undefined,
    cartId: '',
    cartItemsListingGraphs: [],
    cartItemsCount: 0,
    cartData: EMPTY_CART,
    clearCart() {},
    clearCartShipments() {},
    currency: 'USD',
    hasCurrentTaxData: false,
    includeFinancingProcessingFee: false,
    isCalculatingSalesTax: false,
    refetchTaxData() {},
    removeItemsFromCart(items: ShoppingCartItem | ShoppingCartItem[]) {},
    removeShipmentsFromCart(shipments: ShoppingCartShipment | ShoppingCartShipment[]) {},
    setShipToAddressId(id: string) {},
    subtotal: 0,
    shippingTotal: 0,
    shipToAddressId: '',
    showCartDrawer: false,
    taxTotal: 0,
    total: 0,
    setShowCartDrawer() {},
    setIncludeFinancingProcessingFee(v: boolean) {}
});

const { Provider } = shoppingCartContext;

const countCartItems = (cartData: ShoppingCartData) =>
    Object.values(cartData?.items ?? {}).reduce((acc, { quantity }) => acc + quantity, 0);

export const ShoppingCartContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const hasMounted = useHasMounted();
    const [cartId, setCartId] = useState<string>(getUniqueRandomId());
    const [cartData, setCartData] = useState<ShoppingCartData>(getCartDataFromLocalStorage());
    const [cartItemsCount, setCartItemsCount] = useState<number>(countCartItems(cartData));
    const { items: itemsInCart = {}, shipments: shipmentsInCart = {} } = useMemo(() => cartData, [cartData]);
    const [includeFinancingProcessingFee, setIncludeFinancingProcessingFee] = useState<boolean>(false);
    const [allItemsHaveFreeShipping, setAllItemsHaveFreeShipping] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>('USD');
    const [shippingTotal, setShippingTotal] = useState<number>(calculateShippingTotal(shipmentsInCart));
    const [shipToAddressId, setShipToAddressId] = useState<string>('');
    const [buyerFinancingCharge, setBuyerFinancingCharge] = useState<number>(0);
    const [subtotal, setSubtotal] = useState<number>(calculateSubtotal(itemsInCart));
    const [taxTotal, setTaxTotal] = useState<number>(calculateSalesTaxTotal(shipmentsInCart));
    const [showCartDrawer, setShowCartDrawer] = useState<boolean>(false);

    const setAllCartDataProps = (updatedCartData: ShoppingCartData) => {
        setCartData(updatedCartData);
        setCartDataInLocalStorage(updatedCartData);
        setCartItemsCount(countCartItems(updatedCartData));
        setCartId(getUniqueRandomId());
    };

    const calculateCartSalesTaxMutation = useMutation({
        mutationFn: getCalculatedCartSalesTax,
        onSuccess(taxData: CartSalesTaxData) {
            const updatedCartData = updateShipmentTaxData(cartData, taxData);
            setAllCartDataProps(updatedCartData);
        }
    });
    const {
        error: calculateSalesTaxError,
        isPending: isCalculatingSalesTax,
        isSuccess: hasCalculatedSalesTax
    } = calculateCartSalesTaxMutation;

    const { data: cartItemsListingGraphs = [], refetch: refetchCartItemListingGraphs } = useBulkListings({
        ids: Object.keys(itemsInCart)
    });

    useEffect(() => {
        refetchCartItemListingGraphs();
    }, [itemsInCart, refetchCartItemListingGraphs]);

    useEffect(() => {
        const currencyInferredFromFirstItem = cartItemsListingGraphs?.[0]?.currency;
        if (currencyInferredFromFirstItem) {
            setCurrency(currencyInferredFromFirstItem);
        }

        const shipmentValues = Object.values(shipmentsInCart);
        const allItemsHaveSellerPaidShipping =
            shipmentValues.length > 0
                ? shipmentValues.reduce<boolean>(
                      (acc, { hasSellerPaidShipping = false }) => hasSellerPaidShipping && acc,
                      true
                  )
                : false;

        setAllItemsHaveFreeShipping(cartItemsListingGraphs.length > 0 && allItemsHaveSellerPaidShipping);
        setSubtotal(calculateSubtotal(itemsInCart));
        setTaxTotal(calculateSalesTaxTotal(shipmentsInCart));
        setShippingTotal(calculateShippingTotal(shipmentsInCart));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsListingGraphs, shipmentsInCart]);

    useEffect(() => {
        if (!hasMounted || isCalculatingSalesTax || !shipToAddressId) {
            return;
        }

        calculateCartSalesTaxMutation.mutate({ cartData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipToAddressId]);

    useEffect(() => {
        if (!includeFinancingProcessingFee) {
            setBuyerFinancingCharge(0);
            return;
        }

        setBuyerFinancingCharge(calculateBuyerFinancingCharge({ merchandiseTotal: subtotal, shippingTotal }));
    }, [includeFinancingProcessingFee, subtotal, shippingTotal]);

    const addItemsToCart = (items: ShoppingCartItem | ShoppingCartItem[]) => {
        // normalize this to an array
        const itemsAdded = Array.isArray(items) ? items : [items];
        const updatedCartData = getCartWithAddedItems(cartData, itemsAdded);
        setAllCartDataProps(updatedCartData);
    };

    const addShipmentsToCart = (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => {
        // normalize this to an array
        const shipmentsAdded = Array.isArray(shipments) ? shipments : [shipments];
        const updatedCartData = getCartWithAddedShipments(cartData, shipmentsAdded);
        setAllCartDataProps(updatedCartData);
    };

    const clearCartShipments = useCallback(() => {
        const { items } = cartData;

        const updatedCartData = {
            items,
            shipments: {}
        };

        setAllCartDataProps(updatedCartData);
    }, [cartData]);

    const clearCart = useCallback(() => {
        setAllCartDataProps(EMPTY_CART);
    }, []);

    const removeItemsFromCart = (items: ShoppingCartItem | ShoppingCartItem[]) => {
        const itemsRemoved = Array.isArray(items) ? items : [items];
        const updatedCartData = getCartWithRemovedItems(cartData, itemsRemoved);
        setAllCartDataProps(updatedCartData);
    };

    const removeShipmentsFromCart = (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => {
        const shipmentsRemoved = Array.isArray(shipments) ? shipments : [shipments];
        const updatedCartData = getCartWithRemovedShipments(cartData, shipmentsRemoved);
        setAllCartDataProps(updatedCartData);
    };

    const refetchTaxData = useCallback(() => {
        if (isCalculatingSalesTax) {
            return;
        }

        calculateCartSalesTaxMutation.mutate({ cartData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Provider
            value={{
                addItemsToCart,
                addShipmentsToCart,
                allItemsHaveFreeShipping,
                buyerFinancingCharge,
                calculateSalesTaxError: calculateSalesTaxError?.message,
                cartData,
                cartId,
                cartItemsListingGraphs,
                cartItemsCount,
                clearCart,
                clearCartShipments,
                currency,
                hasCurrentTaxData: hasCalculatedSalesTax && !isCalculatingSalesTax && !calculateSalesTaxError,
                includeFinancingProcessingFee,
                isCalculatingSalesTax,
                refetchTaxData,
                removeItemsFromCart,
                removeShipmentsFromCart,
                showCartDrawer,
                setShowCartDrawer,
                subtotal,
                shippingTotal,
                setShipToAddressId,
                setIncludeFinancingProcessingFee,
                taxTotal,
                total: subtotal + shippingTotal + (taxTotal ?? 0) + (buyerFinancingCharge ?? 0)
            }}
        >
            {children}
        </Provider>
    );
};

export const useShoppingCartContext = () => useContext(shoppingCartContext);
