import type { ListingCategory } from '@bladebinge/types';

export const UNMOUNT_ABORT = 'unmount';

export const ORDERED_ACCESSORY_FILTER_TYPES: string[] = ['accessory_category', 'accessory_brand', 'accessory_material'];

export const ORDERED_UNIVERSAL_FILTER_TYPES: string[] = [
    'listing_category',
    'condition',
    'price_range',
    'country_of_origin'
];

export const ORDERED_KNIFE_FILTER_FILTER_TYPES: string[] = [
    'knife_category',
    'knife_brand',
    'knife_lock_type',
    'knife_deployment_method',
    'knife_handle_material',
    'knife_blade_length',
    'knife_blade_steel_type'
];

export const ORDERED_SEARCH_FILTER_CLASS_HEADINGS: string[] = ['universal', 'knife', 'accessory'];

export const LISTING_CATEGORIES: ListingCategory[] = ['knives', 'accessories'];

export const VALID_NUMBER_REGEX = /^\d+(.\d{1,2})?$/;
