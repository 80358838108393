import { ParsedUrlQuery } from 'querystring';
import type { ParsedQuery } from 'query-string';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getUserImageUploads = async (
    {
        queryParams,
        userId
    }: {
        queryParams: ParsedQuery | ParsedUrlQuery;
        userId: string;
    },
    signal?: AbortSignal
) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserImageUploads.build({
                queryParams,
                userId
            }),
            ...(signal ? { options: { signal } } : {})
        });
        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};
